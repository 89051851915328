<!--

© 2020 – 2021 ProCSy JSC https://procsy.ru info@procsy.ru

© АО «ПроКСи», 2020 – 2021 info@procsy.ru

-->

<template>
  <div class="c-app flex-row align-items-center">
    <CContainer>
      <CRow class="justify-content-center">
        <CCol md="6">
          <CCard class="p-4">
            <CCardBody class="login-content">
              <div class="switcher-wrapper">
                <language-switcher></language-switcher>
              </div>

              <form @keyup.enter="login()">
                <h1>{{ $t("login.auth") }}</h1>
                <p class="text-muted">{{ $t("login.signIn") }}</p>
                <CInput
                  :placeholder="$t('menu.login')"
                  autocomplete="username email"
                  v-model="loginData.username"
                >
                  <template #prepend-content>
                    <CIcon name="cil-user" />
                  </template>
                </CInput>
                <CInput
                  :placeholder="$t('menu.password')"
                  type="password"
                  autocomplete="curent-password"
                  v-model="loginData.password"
                >
                  <template #prepend-content>
                    <CIcon name="cil-lock-locked" />
                  </template>
                </CInput>
                <CRow>
                  <CCol col="6" class="text-left">
                    <CButton color="primary" class="px-4" @click="login">{{
                      $t("login.enter")
                    }}</CButton>
                  </CCol>
                  <CCol col="6" class="text-right align-self-center">
                    <CLink @click="goToPasswordRestore()" href="#">{{
                      $t("login.resetPassword")
                    }}</CLink>
                  </CCol>
                </CRow>
              </form>
            </CCardBody>

            <div v-if="loading" class="loading-overlay">
              <CSpinner color="primary" style="width: 3rem; height: 3rem" />
            </div>
          </CCard>
        </CCol>
      </CRow>
    </CContainer>

    <CModal size="lg" :show.sync="licenseModalVisible">
      <div class="d-flex" slot="header">
        <h3>{{ $t("login.licenseAgreement") }}</h3>
      </div>

      <div ref="modalContent" v-if="!!numPages" class="my-modal-content">
        <pdf :src="pdfSrc" v-for="i in numPages" :key="i" :page="i"></pdf>
      </div>

      <div class="d-flex align-items-end" slot="footer">
        <CButton
          class="mr-2"
          variant="outline"
          color="danger"
          @click="declineLicense()"
          >{{ $t("login.declineLicense") }}</CButton
        >
        <CButton variant="outline" color="success" @click="acceptLicense()">{{
          $t("login.acceptLicense")
        }}</CButton>
      </div>
    </CModal>
  </div>
</template>

<script>
import pdf from "vue-pdf";
import { mapActions, mapMutations } from "vuex";

import httpClient from "../../api/httpClient";
import { getEnv } from "../../services";
import utilMessages from "../../utils/messages";
import LanguageSwitcher from "../hauberk/components/LanguageSwitcher.vue";

export default {
  name: "Login",
  components: {
    LanguageSwitcher,
    pdf,
  },
  mounted() {
    if (this.$route.query.error) {
      this.$toast.error(this.$route.query.error);
    }

    if (this.$route.query.message) {
      this.$toast.info(this.$route.query.message);
    }
  },
  data() {
    return {
      loginData: {
        username: "",
        password: "",
      },
      licenseModalVisible: false,
      pdfSrc: null,
      licenseAccepted: false,
      numPages: null,
      pdfUrl: null,
      loading: false,
    };
  },
  methods: {
    ...mapActions(["logout"]),
    ...mapMutations(["setLicenseAccepted"]),
    async login() {
      try {
        this.loading = true;

        const { license_accepted, default_password, email } =
          await this.$store.dispatch("login", this.loginData);


          if (!default_password && license_accepted) {
            this.$router.push("/");
            return;
          }

        if (default_password) {
          this.$router.push({
            name: "changeDefaultPassword",
            params: { userLogin: this.loginData.username },
            query: { hasEmail: !!email },
          });

          return;
        }

        if (!license_accepted) {
          this.showLicense();
        }
      } catch (err) {
        this.$toast.error(utilMessages.errMessage(err));
      } finally {
        this.loading = false;
      }
    },
    goToPasswordRestore() {
      this.$router.push("/password-reset");
    },
    declineLicense() {
      this.licenseModalVisible = false;
      this.licenseAccepted = false;
      this.logout();
    },
    async acceptLicense() {
      try {
        await httpClient.post("/users/accept-license-agreement");
        this.licenseModalVisible = false;
        this.setLicenseAccepted(true);
        this.$router.push("/");
      } catch (error) {
        this.$toast.error(utilMessages.errMessage(error));
      }
    },
    async showLicense() {
      try {
        this.pdfSrc = pdf.createLoadingTask(
          `${getEnv().VUE_APP_BASE_URL}users/view-license-agreement`
        );
        this.pdfSrc.promise.then((pdf) => {
          this.numPages = pdf.numPages;
          this.licenseModalVisible = true;
          this.$nextTick(() => {
            this.$refs.modalContent.scrollTop = 0;
          });
        });
      } catch (error) {
        this.$toast.error(utilMessages.errMessage(error));
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style>
.login-content {
  position: relative;
}

.switcher-wrapper {
  position: absolute;
  right: 10px;
  top: 0;
}

.my-modal-content {
  height: 450px;
  overflow-y: scroll;
}

.loading-overlay {
  position: absolute;
  inset: 0;

  display: flex;
  justify-content: center;
  align-items: center;

  background-color: #00000020;
  z-index: 9999;
}
</style>
